<template>
    <draggable
        :handle="handle"
        v-bind="$props"
        :ghost-class="ghost"
        v-model="list"
        :force-fallback="true"
        @start="onStart"
        @end="onEnd"
        @sort="onSort"
    >
        <slot />
    </draggable>
</template>

<script>
import draggable from 'vuedraggable';
export default {
    components: {
        draggable,
    },
    props: {
        handle: {
            type: String,
            default: '.drag-handle',
        },
        value: {
            type: Array,
            required: true,
        },
        ghost: {
            type: String,
            default: 'ghost',
        },
    },
    computed: {
        list: {
            get() {
                return this.value;
            },
            set(list) {
                this.$emit('change', list);
            },
        },
    },
    methods: {
        onStart(e) {
            this.$emit('start', e);
        },
        onEnd(e) {
            this.$emit('end', e);
        },
        onSort(e) {
            this.$emit('sort', e);
        },
    },
};
</script>
